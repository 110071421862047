import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  contestChart: {
    isLoading: false,
    data: null,
  },
};

//** Dahboard Counts Data **\\
export const dashboardCounts = () => async (dispatch) => {
  try {
    dispatch(
      dashboardSetState([{ key: "dashboardCountsData.isLoading", value: true }])
    );
    const response = await axiosApi.get(`admin/dashboard/count`, null);
    if (response) {
      dispatch(
        dashboardSetState([
          { key: "isError", value: false },
          { key: "isLoading", value: false },
          { key: "dashboardCountsData", value: response?.data?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(dashboardSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

/* -------------------------------------------------------------------------- */
/*                               Contest By Year                              */
/* -------------------------------------------------------------------------- */
export const contestByYearDashboard =
  ({ year }) =>
  async (dispatch) => {
    try {
      dispatch(
        dashboardSetState([{ key: "contestChart.isLoading", value: true }])
      );
      const response = await axiosApi.get(
        `admin/dashboard/contest-by-year?year=${year}`,
        null
      );
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "isError", value: false },
            { key: "contestChart.isLoading", value: false },
            { key: "contestChart.data", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

/* -------------------------------------------------------------------------- */
/*                               Contest By Year                              */
/* -------------------------------------------------------------------------- */
export const participantsByYearDashboard =
  ({ year }) =>
  async (dispatch) => {
    try {
      dispatch(
        dashboardSetState([{ key: "participantChart.isLoading", value: true }])
      );
      const response = await axiosApi.get(
        `admin/dashboard/participant-by-year?year=${year}`,
        null
      );
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "isError", value: false },
            { key: "isLoading", value: false },
            { key: "participantChart", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

/* -------------------------------------------------------------------------- */
/*                               month-wise-revenue By Year                              */
/* -------------------------------------------------------------------------- */
export const monthWiseRevenueDashboard =
  ({ year }) =>
  async (dispatch) => {
    try {
      dispatch(
        dashboardSetState([{ key: "monthWiseRevenue.isLoading", value: true }])
      );
      const response = await axiosApi.get(
        `admin/dashboard/month-wise-revenue?year=${year}`,
        null
      );
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "isError", value: false },
            { key: "isLoading", value: false },
            { key: "monthWiseRevenue", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialStates,
  reducers: {
    dashboardSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { dashboardSetState } = dashboardSlice.actions;

const { reducer } = dashboardSlice;

export default reducer;
